import React, { useState, useEffect } from "react";
import { useHistory, NavLink, useParams } from "react-router-dom";
import "./development.css";
var currentItemClicked;
const Coursedetail = () => {
  const [users, setUsers] = useState([]);
  document.title=window.location.href.replace('https://wuwb.in','WHAT U WANT BE ? ')
  document.title=document.title.replaceAll('/',' | ')
  var id = useParams();
  const history = useHistory();
  try {
    currentItemClicked = id.id;
  } catch (err) {
    history.push("/Development");
  }

  const getUsers = async () => {
    const response = await fetch(
      "https://vaibhavkhushalani.github.io/myData-Json/development.json"
    );
    setUsers(await response.json());
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="Coursedetail">
      <div className="padding">
        {users.map((curlElem) => {
          if (curlElem.Course === currentItemClicked) {
            return (
              <>
                <div className="Coursesdetail mb-3">
                  <div className="row g-0">
                    <img
                      src={curlElem.Coursebanner}
                      className="imgCoursesdetail"
                      alt={curlElem.Course}
                    />

                    <div className="col-md-12 ">
                      <div className="fullcard-body">
                        <p className="Coursestitle">
                          <strong>{curlElem.Course}</strong>
                        </p>
                        <div
                          className="Coursestext"
                          dangerouslySetInnerHTML={{ __html: curlElem.Details }}
                        />
                        <p className="Learncoursestitle">LEARNING PLATFORM</p>
                        <div className="Coursedetailbutton">
                          <NavLink
                            exact
                            to={{
                              pathname: `/courses/Development/${curlElem.Course}/Youtube`,
                            }}
                          >
                            {" "}
                            <button className="Youtubebutton">YOUTUBE</button>
                          </NavLink>
                          <NavLink
                            exact
                            to={{
                              pathname: `/courses/Development/${curlElem.Course}/Website`,
                            }}
                          >
                            <button className="Websitebutton"> WEBSITES</button>
                          </NavLink>
                          <NavLink
                            exact
                            to={{
                              pathname: `/courses/Development/${curlElem.Course}/Books`,
                            }}
                          >
                            <button className="Booksbutton"> BOOKS</button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default Coursedetail;
