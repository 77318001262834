import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Proxy, TitleURL } from "../../Proxy/Proxy";
import Loading from "../Loading/Loading";
import "./blog.css";
import EmailIcon from "@material-ui/icons/Email";
import { getBlogs } from "../../utils/api";

const Blogs = () => {
  const [allblog, setAllBlog] = useState([]);
  const [loading, setLoading] = useState(true);
  document.title = window.location.href.replace(TitleURL, "WHAT U WANT BE ? ");
  document.title = document.title.replaceAll("/", " | ");
  const getblogs = async () => {
    try {
      const { data } = await getBlogs();
      setAllBlog(data);
      setLoading(false);
    } catch (error) {
      const { response } = error;
      console.log(response);
      setLoading(false);
    }
  };
  useEffect(() => {
    getblogs();
  }, []);
  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <div className="blog">
      <h1>
        <strong>BLOGS</strong>
      </h1>

      <NavLink exact to="../postblog">
        <button className="createblog-button ">POST BLOG</button>
      </NavLink>

      <div className="paddingblog">
        {allblog.reverse().map((blog) => {
          var email = "mailto:" + blog.email;

          return (
            <div className="blogcard px-4 py-3 mb-5 " key={blog.id}>
              <div className="row g-0">
                <div className="col-md-12 ">
                  <div className="card-body">
                    <div>
                      <a href={email} className="emailblogicon float-right">
                        <EmailIcon style={{ fontSize: 27 }} />
                      </a>
                      <p className="text-muted">{blog.date} </p>
                    </div>

                    <p className="blogtitle">
                      {blog.title}
                      <i className="text-muted">&nbsp;-{blog.name}</i>
                    </p>

                    <p
                      className="blogtext"
                      dangerouslySetInnerHTML={{
                        __html: blog.description.substring(0, 200),
                      }}
                    />
                    <button className="blog-button float-right">
                      <NavLink
                        exact
                        to={{
                          pathname: `../fullblog/${blog._id}`,
                        }}
                      >
                        READ MORE
                      </NavLink>
                    </button>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Blogs;
