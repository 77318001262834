import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./development.css";
import { useClickOutside } from "react-click-outside-hook";
import SearchIcon from "@material-ui/icons/Search";
import { useRef } from "react";
import { NavLink } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { AnimatePresence, motion } from "framer-motion";

const SearchBarContainer = styled(motion.div)`
  height: "3.8em";
`;
const SearchInput = styled.input`
  &::placeholder {
    transition: all 250ms ease-in-out;
  }
`;
const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 2px 15px;
`;
const containerVariants = {
  expanded: {
    height: "10em",
  },
  collapsed: {
    height: "3.8em",
  },
};
const CCloseIcon = styled(motion.span)`
  color: #bebebe;
  font-size: 23px;
  vertical-align: middle;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    color: #dfdfdf;
  }
`;
const LineSeperator = styled.span`
  display: flex;
  min-width: 100%;
  min-height: 2px;
  background-color: #d8d8d878;
`;
const SearchContent = styled.div``;
const containerTransition = { type: "spring", damping: 22, stiffness: 150 };

const Booksearch = () => {
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    const response = await fetch(
      "https://vaibhavkhushalani.github.io/myData-Json/development.json"
    );
    setUsers(await response.json());
  };
  useEffect(() => {
    getUsers();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [isExpanded, setExpanded] = useState(false);
  const [ParentRef, isClickedOutside] = useClickOutside();
  const inputRef = useRef();

  const expandContainer = () => {
    setExpanded(true);
  };

  const collapseContainer = () => {
    setExpanded(false);
    if (inputRef.current) inputRef.current.value = "";
  };

  useEffect(() => {
    if (isClickedOutside) collapseContainer();
  }, [isClickedOutside]);

  return (
    <SearchBarContainer
      animate={isExpanded ? "expanded" : "collapsed"}
      variants={containerVariants}
      ref={ParentRef}
      transition={containerTransition}
    >
      <div className="d-flex justify-content-center h-100">
        <div className="Boooksearchdiv">
          <SearchInputContainer>
            <SearchIcon />

            <SearchInput
              className="bookssearch"
              placeholder="Search Courses"
              onFocus={expandContainer}
              ref={inputRef}
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
            />
            <a className="search-icon">
              <button className="btn-sm-search">
                <AnimatePresence>
                  {isExpanded && (
                    <CCloseIcon
                      key="close-icon"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      onClick={collapseContainer}
                      transition={{ duration: 0.2 }}
                    >
                      {" "}
                      <CloseIcon />
                    </CCloseIcon>
                  )}
                </AnimatePresence>
              </button>
            </a>
          </SearchInputContainer>
          {isExpanded && <LineSeperator />}
          {isExpanded && (
            <SearchContent>
              <>
                {users
                  .filter((val) => {
                    if (searchTerm == "") {
                      return null;
                    } else if (
                      val.Course.toLowerCase().includes(
                        searchTerm.toLowerCase()
                      )
                    ) {
                      return val;
                    }
                  })
                  .slice(0, 3)
                  .map((curlElem) => {
                    return (
                      <div className="usersearch">
                        <NavLink
                          to={{
                            pathname: `/courses/Development/${curlElem.Course}`,
                          }}
                        >
                          <p className="searchpara" onClick={collapseContainer}>
                            {curlElem.Course}
                          </p>
                        </NavLink>
                      </div>
                    );
                  })}
              </>
            </SearchContent>
          )}
        </div>
      </div>
    </SearchBarContainer>
  );
};

export default Booksearch;
