import React, { useState, useEffect } from "react";
import "./Academics.css";
import { toast } from "react-toastify";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { NavLink, useHistory } from "react-router-dom";

var currentItemClicked;
var currentSubjectClicked;
export const Units = (props) => {
  const [users, setUsers] = useState([]);
  document.title=window.location.href.replace('https://wuwb.in','WHAT U WANT BE ? ')
  document.title=document.title.replaceAll('/',' | ')
  const history = useHistory();
  try {
    currentItemClicked = props.location.state.id;
    currentSubjectClicked = props.location.state.id2;
  } catch (err) {
    history.push("/courses/Academics");
  }

  const getUsers = async () => {
    const response = await fetch(
      "https://vaibhavkhushalani.github.io/myData-Json/academic.json"
    );
    setUsers(await response.json());
    toast(
      <a
        href="https://www.linkedin.com/company/wuwb"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#000", fontweight: "bold" }}
      >
        {<LinkedInIcon style={{ fontSize: 22 }} />} Join Our Community
      </a>,
      {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      {users.map((curlElems, currentItem) => {
        if (curlElems.id === currentItemClicked) {
          return (
            <>
              {curlElems.subjects.map((sub) => {
                if (sub.sub_name === currentSubjectClicked) {
                  return (
                    <>
                      <div className="wrapper" key={sub.unitss}>
                        <h1>
                          <strong>{sub.sub_name}</strong>
                        </h1>

                        <div className="row gy-4 text-center">
                          {sub.units.map((unit) => {
                            // display

                            return (
                              <>
                                <div
                                  className="col-md-4 col-10 mx-auto mb-4 "
                                  key={unit.unitname}
                                  onTouchStart="this.classList.toggle('hover');"
                                >
                                  <div className="container">
                                    <div
                                      className="front"
                                      style={{
                                        backgroundColor: "#160f0f",
                                      }}
                                    >
                                      <div className="inner">
                                        <p>{unit.name}</p>

                                        <span></span>
                                      </div>
                                    </div>
                                    <NavLink
                                      to={{
                                        pathname:
                                          "/courses/Academics/Semester/PDF",
                                        state: {
                                          id: currentItem,
                                          id2: sub.sub_name,
                                          id3: unit.name,
                                        },
                                      }}
                                    >
                                      <div className="back">
                                        <div className="inner">
                                          <p style={{ color: "#000" }}>
                                            {unit.name}
                                          </p>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  );
                } else {
                  return null;
                }
              })}
            </>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export default Units;
