import React from "react";
import Navigation from "./components/navbar/navbar";
import HelpCenter from "./components/Contactus/HelpCenter";
import Suggestions from "./components/Contactus/Suggestions";
import ScrollToTop from "./components/navbar/ScrollToTop";
import Card from "./components/home/Card";
import Footer from "./components/footer/Footer";
import Books from "./components/Books/Books";
import Blogs from "./components/Blogs/Blogs";
import Fullblog from "./components/Blogs/Fullblog";
import Postblog from "./components/Blogs/Postblog";
import About from "./components/About/About";
import Portfolio from "./components/About/Portfolio";
import signin from "./components/login/signin";
import Signup from "./components/login/signup";
import ConfirmationSignup from "./components/login/ConfirmationSignup";
import Reset from "./components/login/reset";
import Newpassword from "./components/login/Newpassword";
import { Coursessa } from "./components/courses/Groupcourses";
import Academics from "./components/courses/Academics/Academics";
import PDF from "./components/courses/Academics/PDF";
import Semester from "./components/courses/Academics/Semester";
import Units from "./components/courses/Academics/Units";
import Development from "./components/courses/Development/Development";
import Coursedetail from "./components/courses/Development/Coursedetail";
import YoutubeCourses from "./components/courses/Development/YoutubeCourses";
import WebsiteCourses from "./components/courses/Development/WebsiteCourses";
import BooksCourses from "./components/courses/Development/BooksCourses";
import Termscondition from "./components/Policies/Termscondition";
import Privacypolicy from "./components/Policies/Privacypolicy";
import { Switch, Route, Redirect } from "react-router-dom";

const Routing = () => {
  return (
    <Switch>
      <Route exact path="/" component={Card} />
      <Route exact path="/Books" component={Books} />
      <Route exact path="/signin" component={signin} />
      <Route exact path="/blogs" component={Blogs} />
      <Route exact path="/fullblog/:id" component={Fullblog} />
      <Route exact path="/Postblog" component={Postblog} />
      <Route exact path="/About" component={About} />
      <Route exact path="/About/portfolio:id" component={Portfolio} />
      <Route exact path="/Signup" component={Signup} />
      <Route
        exact
        path="/ConfirmationSignup/:token"
        component={ConfirmationSignup}
      />
      <Route exact path="/reset" component={Reset} />
      <Route path="/reset/:token/" component={Newpassword} />
      <Route exact path="/HelpCenter" component={HelpCenter} />
      <Route exact path="/Suggestions" component={Suggestions} />
      <Route exact path="/courses" component={Coursessa} />
      <Route exact path="/courses/Academics" component={Academics} />
      <Route exact path="/courses/Academics/Semester/PDF" component={PDF} />
      <Route exact path="/courses/Academics/Semester" component={Semester} />
      <Route path="/courses/Academics/Semester/Units" component={Units} />
      <Route exact path="/courses/Development" component={Development} />
      <Route exact path="/courses/Development/:id" component={Coursedetail} />
      <Route
        exact
        path="/courses/Development/:id/Youtube"
        component={YoutubeCourses}
      />
      <Route
        exact
        path="/courses/Development/:id/Website"
        component={WebsiteCourses}
      />
      <Route
        exact
        path="/courses/Development/:id/Books"
        component={BooksCourses}
      />
      <Route exact path="/Terms&amp;condition" component={Termscondition} />
      <Route exact path="/Privacypolicy" component={Privacypolicy} />
      <Redirect to="/" />
    </Switch>
  );
};

const App = () => {
  return (
    <>
      <ScrollToTop />
      <Navigation />
      <Routing />
      <Footer />
    </>
  );
};

export default App;
