import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import home3 from "../../assests/Home3.png";

const Movingcard1 = () => {
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    const response = await fetch(
      "https://vaibhavkhushalani.github.io/myData-Json/development.json"
    );
    setUsers(await response.json());
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="padding">
      <div className="row ">
        <div className="col-md-7 col-sm-12">
          <img
            className="homeimg3"
            src={home3}
            title="WUWB Courses"
            alt="WUWB Free Lancing"
          />
        </div>
        <div className="col-md-5 col-sm-12">
          <Carousel
            className="carouselfirst"
            fade={true}
            pause={false}
            indicators=""
          >
            {users.map((curlElem, currentItem) => {
              return (
                <Carousel.Item
                  interval={4000}
                  key={curlElem.id}
                  style={{ height: "30em" }}
                >
                  <h1 style={{ color: "#001D93" }}>
                    <strong>Courses</strong>
                  </h1>
                  <div className="cards text-centre shadow">
                    <div className="overflow">
                      <img
                        src={curlElem.imglink}
                        alt="img"
                        className="cards-img-top"
                      />
                    </div>
                    <div className="cards-body text-dark">
                      <h5 className="cards-title">
                        <strong>{curlElem.Course}</strong>{" "}
                      </h5>
                      <p className="card-text text-secondary">
                        <strong>{curlElem.Avg}</strong>
                      </p>
                      <NavLink
                        style={{ color: "#fff" }}
                        to={{
                          pathname: `/courses/Development/${curlElem.Course}`,
                        }}
                      >
                        <p className="btn btn-card">Go</p>
                      </NavLink>
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>

          <div className="Most-populardiv">
            <NavLink
              to="/courses/Development"
              activeStyle={{
                color: "#fff",
              }}
            >
              <button style={{ Float: "right" }} className="Movingcard1button">
                Explore
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Movingcard1;
