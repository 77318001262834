import React, { useState, useEffect } from "react";
import "./style.css";
import { NavLink as RRNavLink } from "react-router-dom";
import myAccount from "../../assests/myaccount.png";
import WUWBLogo from "../../assests/WUWBLOGO.png";
import { getDetails } from "../../utils/api";
import Cookie from "js-cookie";
import { useHistory } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const toggle = () => setIsOpen(!isOpen);

  //logout state
  const [userName, setUserName] = useState(null);
  // getDetails
  const [open, setOpen] = useState(false);
  const userDetails = async () => {
    try {
      const { data } = await getDetails();
      localStorage.setItem("users", data.name);
      setUserName(data.name);
    } catch (error) {
      const { response } = error;
      console.log(response);
    }
  };

  useEffect(() => {
    const name = localStorage.getItem("users");
    if (!name && Cookie.get("wuwbToken")) {
      userDetails();
    } else {
      setUserName(name);
    }
  }, [userDetails]);

  const handleLogout = async () => {
    history.push("/", { replace: true });
    localStorage.clear();
    Cookie.remove("wuwbToken");
    window.location.reload();
  };
  const AfterLogin = () => {
    return (
      <div onMouseEnter={() => setOpen(true)} className="position-relative">
        <div
          className="btn btn-Default btn-sm float-left dropdown-toggle"
          onClick={() => (open ? setOpen(false) : setOpen(true))}
        >
          <img
            className="myaccountjpg"
            src={myAccount}
            title="User"
            alt="account"
          />
        </div>
        {open && (
          <div className="drop-Item">
            <span>
              <NavLink
                tag={RRNavLink}
                exact
                activeClassName="active-class"
                to="../courses"
              >
                {userName ? userName : "User"}
              </NavLink>
            </span>
            <span onClick={handleLogout} style={{ cursor: "pointer" }}>
              {/* <NavLink tag={RRNavLink} exact activeClassName="active-class"> */}
              Sign out
              {/* //</NavLink> */}
            </span>
          </div>
        )}
      </div>
    );
  };
  const BeforeLogin = () => {
    return (
      <div className="loginText">
        <Nav>
          <NavLink
            onClick={toggle}
            tag={RRNavLink}
            exact
            activeClassName="active-class"
            to="/signin"
          >
            Sign in
          </NavLink>
        </Nav>
        <Nav>
          <NavLink
            onClick={toggle}
            tag={RRNavLink}
            exact
            activeClassName="active-class"
            to="/signup"
          >
            Sign up
          </NavLink>
        </Nav>
      </div>
    );
  };

  // console.log(Cookie.get("wuwbToken"));
  return (
    <div>
      <Navbar color="dark" light expand="md" id="myNav">
        <NavLink
          onClick={toggle}
          tag={RRNavLink}
          exact
          activeClassName="active-class-logo"
          to="/"
        >
          <img className="WUWBLogo" src={WUWBLogo} title="Home" alt="logo" />
        </NavLink>

        <NavbarToggler onClick={toggle} />
        <Collapse
          isOpen={isOpen}
          navbar
          className="justify-content-between"
          onMouseLeave={() => setOpen(false)}
        >
          <Nav className="" navbar>
            <NavItem>
              <NavLink
                onClick={toggle}
                tag={RRNavLink}
                exact
                activeClassName="active-class"
                to="/Books"
              >
                Books
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={toggle}
                tag={RRNavLink}
                exact
                activeClassName="active-class"
                to="/courses"
              >
                Courses
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                onClick={toggle}
                tag={RRNavLink}
                exact
                activeClassName="active-class"
                to="/blogs"
              >
                Blogs
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={toggle}
                tag={RRNavLink}
                exact
                activeClassName="active-class"
                to="/About"
              >
                About
              </NavLink>
            </NavItem>

            {/* <Nav className="searchnavbar">
              <Searchbar />
            </Nav> */}
          </Nav>

          {userName ? <AfterLogin /> : <BeforeLogin />}
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Navigation;
