import React from "react";
import "./style.css";
import { NavLink } from "react-router-dom";

export const Courses = (props) => {
  return (
    <div className="cards text-centre shadow">
      <div className="overflow">
        <img src={props.imgsrc} alt="img" className="cards-img-top" />
      </div>
      <div className="cards-body text-dark">
        <h4 className="cards-title">{props.title}</h4>
        <p className="card-text text-secondary">{props.text}</p>
        <NavLink style={{ color: "#fff" }} exact to={props.url}>
          {" "}
          <p style={{ color: "#fff" }} className="btn btn-card">
            Go
          </p>
        </NavLink>
      </div>
    </div>
  );
};

export default Courses;
