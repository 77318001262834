import React, { useState } from "react";
import "./App.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useParams } from "react-router-dom";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {Proxy,TitleURL} from "../../Proxy/Proxy";
const ConfirmationSignup = () => {
  document.title=window.location.href.replace(TitleURL,'WHAT U WANT BE ? ')
  document.title=document.title.replaceAll('/',' | ')
  let id = useParams();
  const history = useHistory();
  toast.configure();

  const [user, setUser] = useState({
    name: "",
    phone: "",
    email: id.token.slice(64),
    password: "",
    cpassword: "",
  });
  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const PostData = async (e) => {
    e.preventDefault();
    const { name, phone, email, password, cpassword } = user;
    if (!name || !email || !phone || !password || !cpassword) {
      toast.warn("Plz fill the field properly", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!name.match(/^[a-zA-Z ]*$/)) {
      toast.error("Username must be alphabet characters only.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!email.includes("@")) {
      toast.error("invalid email address", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!phone.match("^[0-9]{10||11}$")) {
      toast.warn("Please enter valid mobile no.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (password !== cpassword) {
      toast.error("Password not matched", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!password.match("^.*(?=.{5,}).*$")) {
      toast.error("Please enter secure and strong password", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const res = await fetch(Proxy + "/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          phone,
          email,
          password,
          cpassword,
        }),
      });
      await res.json();

      if (res.status === 422) {
        toast.error("E-mail Already exits", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        alert("Successfull Registration");
        history.push("/signin");
      }
    }
  };

  return (
    <div className="App">
      <div className="login-box">
        <h2>Create new Account</h2>
        <form method="POST">
          <div className="row">
            <div className="col-md-6">
              <div className="user-box">
                <input
                  type="text"
                  name="name"
                  value={user.name}
                  onChange={handleInputs}
                  required=""
                ></input>
                <label>Name</label>
              </div>
            </div>

            <div className="col-md-6">
              <div className="user-box">
                <input
                  type="tel"
                  name="phone"
                  value={user.phone}
                  onChange={handleInputs}
                  required=""
                ></input>
                <label>Phone-no</label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <div className="user-box">
                <input
                  type="E-mail"
                  name="email"
                  value={user.email}
                  required=""
                />
                <label>E-mail  <CheckBoxIcon  fontSize="small" /> </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="user-box">
                <input
                  type="password"
                  name="password"
                  value={user.password}
                  onChange={handleInputs}
                  required=""
                />
                <label>Password</label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="user-box">
                <input
                  type="password"
                  name="cpassword"
                  value={user.cpassword}
                  onChange={handleInputs}
                  required=""
                />
                <label>Re-type Password</label>
              </div>
            </div>
          </div>
          <div>
            <button
              href="#"
              className="submitbutton"
              type="submit"
              onClick={PostData}
            >
              Submit{" "}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ConfirmationSignup;
