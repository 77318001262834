import axios from "axios";
import Cookies from "js-cookie";
import { Proxy } from "../Proxy/Proxy";

const API = axios.create({
  baseURL: Proxy,
});

API.interceptors.request.use((config) => {
  const token = Cookies.get("wuwbToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const loginAPI = (SigninData) => API.post("/signin", SigninData);
export const googleAPI = (SigninData) => API.post("/Google-signin", SigninData);
export const getDetails = () => API.get("/getUserDetails");
export const getBlogs = () => API.get("/getAllBlogs");
export const getOneBlog = (id) => API.get(`/getBlogDetail/${id}`);
export const postMessage = (data) => API.post(`/Contactus`, data);
export const postBlog = (data) => API.post(`/post-blog`, data);
// export const logOutUser = () => API.get("/logout");
