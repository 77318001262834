import React, { useState } from "react";
import "./App.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import {Proxy,TitleURL }from "../../Proxy/Proxy";
import { GoogleLogin } from "react-google-login";
const Signup = () => {
  document.title = window.location.href.replace(
    TitleURL,
    "WHAT U WANT BE ? "
  );
  document.title = document.title.replaceAll("/", " | ");
  const history = useHistory();
  const [user, setUser] = useState({
    email: "",
  });
  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  //google sign-up
  const random = (length = 8) => {
    // Declare all characters
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    // Pick characers randomly
    let str = "";
    for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
  };
  const SuccessresponseGoogle = async (response) => {
    var email = response.profileObj.email;
    const res = await fetch(Proxy + "/Gmailverification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        email,
      }),
    });

    if (res.status === 422) {
      toast.error("E-mail Already exits", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      history.push(
        `/Confirmationsignup/${random(64)}${response.profileObj.email}`
      );
    }
  };
  const FailureresponseGoogle = () => {
    toast.error("Failed Try Again Later ", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  
  const PostData = async (e) => {
    e.preventDefault();
    const { email } = user;

    const res = await fetch(Proxy + "/emailverification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        email,
      }),
    });

    if (!email) {
      toast.error("Plz fill the field properly", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!email.includes("@")) {
      toast.error("invalid email address", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (res.status === 422) {
      toast.error("E-mail Already exits", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      alert("Check Your E-mail For verification of Account");
      history.push("/");
    }
  };

  return (
    <div className="App">
      <div className="login-box">
        <h2>E-mail Verification</h2>
        <div className="Notessignup">
          &#9733; Please check your Primary, Social, and Promotion areas of your
          Inbox at the time of verification.
        </div>
        <br />
        <br />
        <form method="POST">
          <div className="row">
            <div className="col-md">
              <div className="user-box">
                <input
                  type="E-mail"
                  name="email"
                  value={user.email}
                  onChange={handleInputs}
                  required=""
                />
                <label>E-mail</label>
              </div>
            </div>
          </div>

          <div>
            <a href="/#" className="submittext" onClick={PostData}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </a>
            <ToastContainer />
          </div>
          <hr style={{ color: "#fff" }} />
          <p style={{ color: "#fff", textAlign: "center" }}>Or</p>
          <GoogleLogin
            className="google-Signup"
            clientId="725333776628-l3meog5chh7iabm2747hphbocal9u49s.apps.googleusercontent.com"
            buttonText="Sign-up"
            onSuccess={SuccessresponseGoogle}
            onFailure={FailureresponseGoogle}
            cookiePolicy={"single_host_origin"}
          />
        </form>
      </div>
    </div>
  );
};
export default Signup;
