import React from "react";
import img1 from "../../assests/vaibhav.png";
import img2 from "../../assests/pansari.png";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import InstagramIcon from "@material-ui/icons/Instagram";
import EmailIcon from "@material-ui/icons/Email";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { NavLink } from "react-router-dom";
import "./aboutus.css";
const About = () => {
  document.title = window.location.href.replace(
    "https://wuwb.in",
    "WHAT U WANT BE ? "
  );
  document.title = document.title.replaceAll("/", " | ");
  return (
    <div className="About">
      <div className="container">
        <h1>
          <strong>ABOUT US</strong>
        </h1>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="padding">
              <div className="aboutcard" style={{ backgroundColor: "#0c1433" }}>
                <div className="image-top">
                  <img
                    className="card-img-tops"
                    src={img1}
                    alt="VAIBHAV KHUSHALANI"
                    title="VAIBHAV KHUSHALANI"
                  />
                </div>
                <div className="aboutcard-body">
                  <h3 className="card-title">VAIBHAV KHUSHALANI</h3>
                  <h6 className="card-title">WEB DEVELOPER (MERN)</h6>
                  <p className="about-card-text">(Co-Founder)</p>
                  {/* <NavLink
                    exact
                    to={{
                      pathname: `../About/portfolio${"VAIBHAV_KHUSHALANI"}`,
                    }}
                  > */}
                  <button
                    className="btn-portfolio"
                    onClick={() =>
                      window.open("https://easyfolio.wuwb.in/", "_blank")
                    }
                  >
                    PORTFOLIO
                  </button>
                  {/* </NavLink> */}
                  <ul className="socialicon">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/vaibhav-khushalani-760217136/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="linkedInIcon"
                      >
                        <LinkedInIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/vaibhav_khushalani/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="instagramicon"
                      >
                        <InstagramIcon />
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        href="mailto: vaibhavkhushlani04@gmail.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="emailicon"
                      >
                        <EmailIcon />
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        href="https://api.whatsapp.com/send?phone=+917536024636"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="whatsappicon"
                      >
                        <WhatsAppIcon />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="padding">
              <div className="aboutcard" style={{ backgroundColor: "#0c1433" }}>
                <div className="image-top">
                  <img
                    className="card-img-tops"
                    src={img2}
                    alt="SAURABH PANSARI"
                    title="SAURABH PANSARI"
                  />
                </div>
                <div className="aboutcard-body">
                  <h3 className="card-title">SAURABH PANSARI</h3>
                  <h6 className="card-title">UI-UX DESIGNER</h6>
                  <p className="about-card-text">(Co-Founder)</p>
                  <NavLink
                    exact
                    to={{
                      pathname: `../About/portfolio${"SAURABH_PANSARI"}`,
                    }}
                  >
                    <button className="btn-portfolio">PORTFOLIO</button>
                  </NavLink>
                  <ul className="socialicon">
                    <li>
                      <a
                        href="https://www.linkedin.com/in/saurabh-pansari-53296b1a9"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="linkedInIcon"
                      >
                        <LinkedInIcon />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/saurabhpansari/?utm_medium=copy_link"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="instagramicon"
                      >
                        <InstagramIcon />
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        href="mailto: pansari00saurabh@gmail.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="emailicon"
                      >
                        <EmailIcon />
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a
                        href="https://api.whatsapp.com/send?phone=+918005901030"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="whatsappicon"
                      >
                        <WhatsAppIcon />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
