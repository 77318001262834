import React, { useState, useEffect } from "react";
import "./blog.css";
import { useHistory, NavLink, useParams } from "react-router-dom";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import ShareIcon from "@material-ui/icons/Share";
import ReportIcon from "@material-ui/icons/Report";
import EmailIcon from "@material-ui/icons/Email";
import { toast } from "react-toastify";
import Helmet from "react-helmet";
import Loading from "../Loading/Loading";
import { TitleURL } from "../../Proxy/Proxy";
import { getOneBlog } from "../../utils/api";

toast.configure();

const Fullblog = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  document.title = window.location.href.replace(TitleURL, "WHAT U WANT BE ? ");
  document.title = document.title.replaceAll("/", " | ");

  const getblog = async () => {
    try {
      const { data } = await getOneBlog(id);
      setBlog(data);
      setLoading(false);
    } catch (error) {
      const { response } = error;
      console.log(response);
      history.push("/blogs");
      setLoading(false);
    }
  };
  useEffect(() => {
    getblog();
  }, []);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  const email = "mailto:" + blog.email;
  return (
    <div className="blog">
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={blog.description} />
      </Helmet>
      <h1>
        <strong>BLOGS</strong>
      </h1>
      <div className="row">
        <NavLink exact to="../postblog">
          <button className="createfullblog-button float-right">
            POST BLOG
          </button>
        </NavLink>

        <div className="paddingblog">
          <div className="fullblogcard mb-3">
            <div className="row g-0">
              <div className="col-md-12 ">
                <div className="fullcard-body">
                  <div className="socialblogicon">
                    <NavLink exact to="../HelpCenter">
                      <a
                        title="Report"
                        href="curlElem.mail"
                        className="emailblogicon float-right"
                      >
                        <ReportIcon style={{ fontSize: 27 }} />
                      </a>
                    </NavLink>

                    <p
                      onClick={() => {
                        toast.success("Link Copied", {
                          position: "top-center",
                          autoClose: 5000,

                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });

                        navigator.clipboard.writeText(window.location.href);
                      }}
                      title="share"
                      className="emailblogicon float-right"
                    >
                      <ShareIcon style={{ fontSize: 27 }} />
                    </p>
                    <a
                      href={email}
                      title="E-mail"
                      className="emailblogicon float-right"
                    >
                      <EmailIcon style={{ fontSize: 27 }} />
                    </a>
                    <p className="fulltext-muted">{blog.date}</p>
                  </div>

                  <p className="fullblogtitle">{blog.title}</p>
                  <p className="fullcard-author">By- {blog.name}</p>

                  <br />
                  <p
                    className="fullblogtext"
                    dangerouslySetInnerHTML={{
                      __html: blog.description,
                    }}
                  />

                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Fullblog;
