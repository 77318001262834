import React, { useState, useEffect } from "react";
import "./Academics.css";
import { toast } from "react-toastify";
import TelegramIcon from "@material-ui/icons/Telegram";
import { NavLink, useHistory } from "react-router-dom";
var currentItemClicked;

export const Semester = (props) => {
  const [users, setUsers] = useState([]);
  document.title=window.location.href.replace('https://wuwb.in','WHAT U WANT BE ? ')
  document.title=document.title.replaceAll('/',' | ')
  const history = useHistory();
  try {
    currentItemClicked = props.location.state.id;
  } catch (err) {
    history.push("/courses/Academics");
  }

  const getUsers = async () => {
    const response = await fetch(
      "https://vaibhavkhushalani.github.io/myData-Json/academic.json"
    );
    setUsers(await response.json());
    toast.info(<a href="https://t.me/whatuwantbe"
    target="_blank"
    rel="noopener noreferrer"
    style={{ color:'#fff',fontweight:'bold' }}
  >
     {<TelegramIcon style={{ fontSize: 20 }}/>} Join Our Community 
  </a>,
  {
    position: "bottom-right",
    autoClose: true,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }
  );
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      {users.map((curlElems, currentItem) => {
        if (curlElems.id === currentItemClicked) {
          return (
            <>
              <div className="wrapper" key={curlElems.id}>
                <h1>
                  <strong>{curlElems.name}</strong>
                </h1>
                <div className="row gy-4 text-center">
                  {curlElems.subjects.map((sub) => {
                    return (
                      <div
                        key={sub.sub_name}
                        className="col-md-4 col-10 mx-auto mb-4 "
                        onTouchStart="this.classList.toggle('hover')"
                      >
                        <div className="container">
                          <div
                            className="front"
                            style={{
                              backgroundColor: "#160f0f",
                            }}
                          >
                            <div className="inner">
                              <p>{sub.sub_name}</p>

                              <span></span>
                            </div>
                          </div>
                          <div className="back">
                            <NavLink
                              to={{
                                pathname: "/courses/Academics/Semester/Units",
                                state: {
                                  id: currentItem,
                                  id2: sub.sub_name,
                                },
                              }}
                            >
                              <div className="inner">
                                <div>
                                  {sub.units.map((unit) => {
                                    return (
                                      <div key={unit.name}>{unit.name}</div>
                                    );
                                  })}
                                </div>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

export default Semester;
