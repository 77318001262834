import React from "react";
import { useHistory, useParams } from "react-router-dom";
var link;

const Portfolio = () => {
  document.title = window.location.href.replace(
    "https://wuwb.in",
    "WHAT U WANT BE ? "
  );
  document.title = document.title.replaceAll("/", " | ");
  var id = useParams();
  
  const history = useHistory();
  function getLink() {
    if (id.id === "VAIBHAV_KHUSHALANI") {
      link =
        "https://drive.google.com/file/d/1Vj6u0oRazvCSgiWtdlmARm-XxdgeDz7T/preview";
    } else if (id.id === "SAURABH_PANSARI") {
      link =
        "https://drive.google.com/file/d/1D90fqSe3-gig3M8raEPC5xlTXdjiOiyz/preview";
    } else {
      history.push("/About");
    }
    return link;
  }

  try {
    link = getLink();
  } catch (err) {
    history.push("/About");
  }

  return (
    <>
      <div>
        <iframe
          src={link}
          allowtransparency="true"
          title={id.id}
          width="95%"
          height="570"
          frameBorder="0"
          scrolling="no"
          seamless=""
        />
      </div>
    </>
  );
};

export default Portfolio;
