import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import home4 from "../../assests/Home4.png";

const Movingcard2 = () => {
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    const response = await fetch(
      "https://vaibhavkhushalani.github.io/myData-Json/books.json"
    );
    setUsers(await response.json());
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="padding">
      <div className="row ">
        <div className="col-md-5 col-sm-12">
          <Carousel fade={true} pause={false} indicators="">
            {users.map((curlElem) => {
              return (
                <Carousel.Item interval={4000} key={curlElem.id}>
                  <h1 style={{ color: "#001D93" }}>
                    <strong>Books</strong>
                  </h1>
                  <div className="cards text-centre shadow">
                    <div className="overflow">
                      <img
                        src="https://drive.google.com/uc?export=download&id=1I9q4ng98xEYlALFYCGNsbU2nkE1p8es7"
                        alt="img"
                        className="cards-img-top"
                      />
                    </div>
                    <div className="cards-body text-dark">
                      <h5 className="bookshomecard">
                        <strong>{curlElem.Book.substring(0, 21)}</strong>
                      </h5>
                      <p className="card-text text-secondary">
                        <strong>{curlElem.author.substring(0, 15)}</strong>
                      </p>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={curlElem.Link}
                        className="btn btn-card"
                      >
                        GO
                      </a>
                      <NavLink
                        style={{ color: "#fff" }}
                        to={{
                          pathname: "/Books",
                        }}
                      >
                        <p className="Movingcard2button">Explore</p>
                      </NavLink>
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        <div className="col-md-7 col-sm-12">
          <img
            className="homeimg4"
            src={home4}
            title="WUWB Free Lancing"
            alt="WUWB Books"
          />
        </div>
      </div>
    </div>
  );
};

export default Movingcard2;
