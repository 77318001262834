import React, { useState, useEffect } from "react";
import "./pdf.css";
import { useHistory } from "react-router-dom";

var currentItemClicked;
var currentSubjectClicked;
var cuurentUnit;
const PDF = (props) => {
  const [users, setUsers] = useState([]);
  document.title=window.location.href.replace('https://wuwb.in','WHAT U WANT BE ? ')
  document.title=document.title.replaceAll('/',' | ')
  const history = useHistory();
  try {
    currentItemClicked = props.location.state.id;
    currentSubjectClicked = props.location.state.id2;
    cuurentUnit = props.location.state.id3;
  } catch (err) {
    history.push("/courses/Academics");
  }

  const getUsers = async () => {
    const response = await fetch(
      "https://vaibhavkhushalani.github.io/myData-Json/academic.json"
    );
    setUsers(await response.json());
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="Title">
      <h4>
        <strong>
          {currentSubjectClicked} : {cuurentUnit}
        </strong>
      </h4>
      {users.map((curlElems) => {
        if (curlElems.id === currentItemClicked) {
          return (
            <>
              {curlElems.subjects.map((sub) => {
                if (sub.sub_name === currentSubjectClicked) {
                  return (
                    <>
                      {sub.units.map((unit) => {
                        if (unit.name === cuurentUnit) {
                          return (
                            <>
                              <div
                                style={{
                                  width: `100%`,
                                  height: `670px`,
                                  position: `relative`,
                                }}
                              >
                                <iframe
                                  src={unit.link}
                                  allowtransparency="true"
                                  width="95%"
                                  height="670"
                                  frameBorder="0"
                                  scrolling="no"
                                  seamless=""
                                  title={cuurentUnit}
                                />
                                
                              </div>
                            </>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </>
                  );
                } else {
                  return null;
                }
              })}
            </>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default PDF;
