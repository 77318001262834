import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./development.css";
var currentItemClicked;

const YoutubeCourses = () => {
  var id = useParams();
  const [users, setUsers] = useState([]);
  document.title=window.location.href.replace('https://wuwb.in','WHAT U WANT BE ? ')
  document.title=document.title.replaceAll('/',' | ')
  const history = useHistory();
  try {
    currentItemClicked = id.id;
  } catch (err) {
    history.push("/Development");
  }

  const getUsers = async () => {
    const response = await fetch(
      "https://vaibhavkhushalani.github.io/myData-Json/development.json"
    );
    setUsers(await response.json());
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="Coursedetail">
      <div className="padding">
        {users.map((curlElem) => {
          if (curlElem.Course === currentItemClicked) {
            return (
              <>
                <div className="Coursesdetail mb-3">
                  <div className="row g-0">
                    <img
                      src={curlElem.Coursebanner}
                      className="imgCoursesdetail"
                      alt={curlElem.Course}
                    />

                    <div className="col-md-12 ">
                      <div className="Coursedetailbutton">
                        <button className="Youtubebutton">YOUTUBE</button>

                        <div className="fullcard-body">
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr className="coursesHeading">
                                <th scope="col">Youtubers</th>
                                <th scope="col">Subscribers</th>
                                <th scope="col">Views</th>
                                <th scope="col">Link</th>
                              </tr>
                            </thead>
                            <tbody>
                              {curlElem.Youtuber.map((youtube) => {
                                return (
                                  <tr className="coursestexts">
                                    <td>{youtube.Youtuber_name}</td>
                                    <td>{youtube.Subcribers}</td>
                                    <td>{youtube.Views}</td>
                                    <td>
                                      <button className="LinkBody">
                                        <a
                                          href={youtube.Link}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          style={{ color: "white" }}
                                        >
                                          Link
                                        </a>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default YoutubeCourses;
