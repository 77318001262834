import React, { useState } from "react";
import "./App.css";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Proxy,TitleURL} from "../../Proxy/Proxy";

const Newpassword = () => {
  document.title=window.location.href.replace(TitleURL,'WHAT U WANT BE ? ')
  document.title=document.title.replaceAll('/',' | ')
  const history = useHistory();
  const [user, setUser] = useState({ password: "", cpassword: "" });
  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };
  const { token } = useParams();

  const PostData = async (e) => {
    e.preventDefault();
    const { password, cpassword } = user;

    if (!password.match("^.*(?=.{5,}).*$")) {
      toast.error("Please enter secure and strong password", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (password !== cpassword) {
      toast.error("Password not matched", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const res = await fetch(Proxy + "/new-password", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        crossDomain: true,
        credentials: "include",
        body: JSON.stringify({
          password,
          cpassword,
          token,
        }),
      });
      if (res.status === 422) {
        toast.error("Session Expired!! Try Again Later", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        window.alert("Password Changed successfully");
        history.push("/signin");
      }
    }
  };

  return (
    <div className="App">
      <div class="login-box">
        <h2>Reset Password</h2>
        <form method="Post">
          <div class="user-box">
            <input
              type="password"
              id="password"
              name="password"
              autoComplete="off"
              value={user.password}
              onChange={handleInputs}
              required=""
            />
            <label>Enter New password</label>
          </div>
          <div class="user-box">
            <input
              type="password"
              id="password"
              name="cpassword"
              autoComplete="off"
              value={user.cpassword}
              onChange={handleInputs}
              required=""
            />
            <label>Confirm Password</label>
          </div>

          <a href="/#" className="submittext" onClick={PostData}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </a>
        </form>
      </div>
    </div>
  );
};

export default Newpassword;
