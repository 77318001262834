import React from "react";
import Common from "./Common";

const Suggestions = () => {
  return (
    <>
      <Common name=" Have Some Suggestions" />
    </>
  );
};

export default Suggestions;
