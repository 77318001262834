import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./blog.css";
import { Editor } from "react-draft-wysiwyg";
import { toast } from "react-toastify";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { EditorState } from "draft-js";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import Loading from "../Loading/Loading";
import { Proxy, TitleURL } from "../../Proxy/Proxy";
import { getDetails, postBlog } from "../../utils/api";
import Cookie from "js-cookie";
export default function Postblog() {
  const historys = useHistory();
  const [loading, setLoading] = useState(true);
  toast.configure();
  document.title = window.location.href.replace(TitleURL, "WHAT U WANT BE ? ");
  document.title = document.title.replaceAll("/", " | ");
  //for handling inputs
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState(EditorState.createEmpty());

  //Date format dd-mm-yyyy
  let today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  today = dd + "/" + mm + "/" + yyyy;

  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const newPost = {
      title: title,
      name: userData.name,
      email: userData.email,
      date: today,
      description: stateToHTML(description.getCurrentContent()),
    };
    if (!title) {
      toast.warn("Title Field cannot be empty", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!description.getCurrentContent().hasText()) {
      toast.warn("Blog cannot be empty", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      try {
        await postBlog(newPost);
        setLoading(false);
        setTitle("");
        setDescription(EditorState.createEmpty());
        toast.success(
          <a
            href="https://chat.whatsapp.com/FRDTyaZuum9KsWJbNYqR9o"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#fff", fontweight: "bold" }}
          >
            {<WhatsAppIcon style={{ fontSize: 20 }} />} Join Our Community
          </a>,
          {
            position: "bottom-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        historys.push("/blogs");
      } catch (error) {
        const { response } = error;
        console.log(response);
        setLoading(false);
      }
    }
  };

  //for automatic work
  const [userData, setUserData] = useState({
    name: "",
    email: "",
  });
  const userDetails = async () => {
    try {
      const { data } = await getDetails();
      setUserData({ ...userData, name: data.name, email: data.email });
      setLoading(false);
    } catch (error) {
      const { response } = error;
      console.log(response);
      setLoading(false);
    }
  };

  const checkLogin = () => {
    if (!Cookie.get("wuwbToken")) {
      toast.error("Login to access this page", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      historys.push("/signin");
    } else {
      userDetails();
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  // for auntentication

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <div className="Most-populardiv">
      <div className="About">
        <div className="container">
          <form noValidate onSubmit={onSubmit}>
            <div className="postblogfield">
              <div className="row justify-content-md-center">
                <div className="col-md-2 col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="NAME"
                    value={userData.name}
                  />
                </div>
                &nbsp; &nbsp; &nbsp;
                <div className="col-md-6 col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Title of Your Blog"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                &nbsp; &nbsp; &nbsp;
                <div className="col-md-3 col-sm-12">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="E-MAIL"
                    value={userData.email}
                  />
                </div>
                &nbsp; &nbsp; &nbsp;
              </div>
            </div>
            <div className="sr-only">
              <input
                placeholder="Date"
                className="form-control"
                value={today}
              />
            </div>

            <div>
              <Editor
                editorState={description}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                wrapperStyle={{
                  border: "2px solid green",
                  marginBottom: "10px",
                  backgroundColor: "#ffffffb4",
                }}
                editorStyle={{ height: "400px", padding: "10px" }}
                toolbar={{
                  options: [
                    "inline",
                    "blockType",
                    "fontSize",
                    "list",
                    "link",
                    "textAlign",
                    // "embedded",
                    "fontFamily",
                    "remove",
                    "history",
                  ],
                  inline: { inDropdown: true },
                  list: { inDropdown: true },
                  textAlign: { inDropdown: true },
                  link: { inDropdown: true },
                  history: { inDropdown: true },
                }}
                onEditorStateChange={(editorState) =>
                  setDescription(editorState)
                }
              />
            </div>
            <div className="Most-populardiv">
              <button type="submit" className="postblog-button">
                Submit Blog
              </button>
            </div>
          </form>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
    </div>
  );
}
