import React, { useEffect, useState } from "react";
import Courses from "./Courses";
import { useHistory } from "react-router-dom";
import "./style.css";
import img1 from "../../assests/Ac.png";
import img2 from "../../assests/Dc.png";
import { toast } from "react-toastify";
import Loading from "../Loading/Loading";
import { TitleURL } from "../../Proxy/Proxy";

export const Coursessa = () => {
  document.title = window.location.href.replace(TitleURL, "WHAT U WANT BE ? ");
  document.title = document.title.replaceAll("/", " | ");

  return (
    <div className="course">
      <div className="container-fluid">
        <div className="row gy-5">
          <div className="col-sm-12 col-md-6 col-lg-6  ">
            <div className="paddingcourse">
              <Courses
                imgsrc={img1}
                title="Academics Courses"
                url="../courses/Academics"
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="paddingcourse">
              <Courses
                imgsrc={img2}
                title="Development Courses"
                url="../courses/Development"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Coursessa;
