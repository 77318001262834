import React from "react";
import "./style.css";
import CarouselContainer from "./banner.js";
import Movingcard1 from "./Movingcard1.jsx";
import Movingcard2 from "./Movingcard2";
import home1 from "../../assests/Home1.png";
import home2 from "../../assests/Home2.png";
import { TitleURL } from "../../Proxy/Proxy";
const Card = () => {
  document.title = window.location.href.replace(TitleURL, "WHAT U WANT BE ? ");
  document.title = document.title.replaceAll("/", "");
  return (
    <div className="homebg">
      {/* Banner card */}
      <div className="bannercard">
        <CarouselContainer />
      </div>

      {/* First Content */}

      <div className="row">
        <div className=" col-md-4 col-sm-6 ">
          <img className="homeimg" src={home1} title="WUWB" alt="WUWB" />
        </div>
        <div className="col-md-8 col-sm-6">
          <br />
          <br />
          <h1 className="hometitle">
            <span>What U Want Be?</span>
          </h1>
          <h1 className="IIndheading">
            <span>(Unlock The Way That You Deserve)</span>
            <span>Our Services:</span>
          </h1>
          <ul className="home-list">
            <li className="home-content">Free Courses</li>
            <li className="home-content">Study Material</li>
            <li className="home-content">B-tech Notes</li>
            <li className="home-content">Pdf Books</li>
            <li className="home-content">Blog</li>
            <li className="home-content">Holistic Development</li>
          </ul>
        </div>
      </div>

      {/* 
<Google/> */}

      {/* Second Content */}
      <div className="row">
        <div className="col-md-8 col-sm-6">
          <h1 className="IInd-hometitle">
            <span>Professional Services</span>
            <span className="IIIndheading">
              (Find Cost Effective Freelancers)
            </span>
          </h1>
          <h6 className="hometitleh6">
            We at <strong>WUWB</strong> partnered with a number of highly
            talented <strong>Tech</strong> and <strong>Non-tech</strong>{" "}
            Freelancers in many fields to make your work easier at a low cost,
            especially if you are working on a startup and need economical
            development.
          </h6>

          <ul className="IIndhome-list">
            <li className="home-content">Ui-Ux Designer</li>
            <li className="home-content">Video Editor</li>
            <li className="home-content">Content Writer</li>
            <li className="home-content">Social Media Handler</li>
            <li className="home-content">Media Marketer</li>
            <li className="home-content">App Developer</li>
          </ul>
        </div>
        <div className="col-md-4 col-sm-6">
          <img
            className="homeimg2"
            src={home2}
            title="WUWB Free Lancing"
            alt="WUWB Free Lancing"
          />
        </div>
        <div className="Most-populardiv">
          <a
            href="https://forms.gle/j2ZPGUcr2sUurBCKA"
            target="_blank"
            rel="noopener noreferrer"
            className="Requestbutton"
          >
            Request
          </a>
        </div>
      </div>

      {/* Ist Moving Card */}

      <Movingcard1 />

      {/* 2nd Moving card */}

      <Movingcard2 />
      {/* Youtube Iframe */}

      <div className="youtube-iframe">
        <iframe
          className="responsiveyoutube-iframe"
          src="https://www.youtube-nocookie.com/embed/vd_TxaQMBJ0?rel=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture "
          allowfullscreen="true"
        ></iframe>
      </div>

      <br />
      <br />
      <br />
    </div>
  );
};

export default Card;
