import React, { useState, useEffect } from "react";
import Booksearch from "./Booksearch";
// import { useHistory } from "react-router-dom";
// import { toast } from "react-toastify";
import "./style.css";
import Loading from "../Loading/Loading";
import { TitleURL } from "../../Proxy/Proxy";
const arr = [];
while (arr.length < 20) {
  var r = Math.floor(Math.random() * 50);
  if (arr.indexOf(r) === -1) {
    arr.push(r);
  }
}

const Books = () => {
  const [users, setUsers] = useState([]);
  document.title = window.location.href.replace(TitleURL, "WHAT U WANT BE ? ");
  document.title = document.title.replaceAll("/", " | ");
  const getBooks = async () => {
    setLoading(true);
    const response = await fetch(
      "https://vaibhavkhushalani.github.io/myData-Json/books.json"
    );
    setUsers(await response.json());
    setLoading(false);
  };
  useEffect(() => {
    getBooks();
  }, []);

  //for  Authentication
  // const history = useHistory();
  const [loading, setLoading] = useState(true);
  // const callCoursessaPage = async () => {
  //   try {
  //     const res = await fetch(Proxy + "/Course", {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       crossDomain: true,
  //       credentials: "include",
  //     });

  //     await res.json();
  //     if (!res.status === 200) {
  //       const error = new Error(res.error);
  //       throw error;
  //     }
  //   } catch (err) {
  //     toast.error("You must Login to access this Page", {
  //       position: "top-center",
  //       autoClose: 5000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     history.push("/signin");
  //   }
  //   setLoading(false);
  // };

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  return (
    <div className="explore">
      <h1>
        <strong>Books</strong>
      </h1>
      <Booksearch />
      <div className="reqBook-btn">
        <button
          onClick={() =>
            window.open("https://forms.gle/4WeGwgNxkhjadDGX6", "_blank")
          }
        >
          Request Book
        </button>
      </div>
      <div className="paddingbook"></div>

      <div className="Coursesdetail mb-3">
        <div className="row g-0">
          <img
            src="https://drive.google.com/uc?export=download&id=1I9q4ng98xEYlALFYCGNsbU2nkE1p8es7"
            alt="img"
            className="imgCoursesdetail"
          />
          <div className="col-md-12 ">
            <div className="Coursedetailbutton">
              <button className="Youtubebutton">Top Rated</button>

              <div className="fullcard-body">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr className="coursesHeading">
                      <th scope="col">Name of Books</th>
                      {/* <th scope="col">Author</th> */}
                      <th scope="col">Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((curlElem, currentItem) => {
                      if (arr.includes(currentItem)) {
                        return (
                          <tr className="coursestexts">
                            <td className="bookname_texts">{curlElem.Book}</td>

                            <td>
                              <button className="LinkBody">
                                <a
                                  href={curlElem.Link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{ color: "white" }}
                                >
                                  Link
                                </a>
                              </button>
                            </td>
                          </tr>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Books;
