import React from "react";
import styled from "styled-components";
import "./style.css";
import wuwbtitle from "../../assests/WUWBLOGO.png";
import { NavLink } from "react-router-dom";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TelegramIcon from "@material-ui/icons/Telegram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

function Footer() {
  let url = "";

  return (
    <FooterContainer>
      <div className="footer-middle">
        <div className="container">
          <div className="row">
            {/*coloumn1*/}
            <div className="col-sm-12 col-md-3">
              <ul className="TitleFooter">
                <h1>
                  <a href="/">
                    <img className="wuwbtitle" src={wuwbtitle} alt="wuwb" />
                  </a>
                </h1>
              </ul>
            </div>
            {/*coloumn2*/}
            <div className="col-sm-12 col-md-3">
              <h2 className="list-unstyled-headers">Information</h2>
              <ul className="list-unstyled">
                <li href={url}>
                  <NavLink exact to="../HelpCenter">
                    &#x27A4; Help Center
                  </NavLink>
                </li>
                <li href={url}>
                  <NavLink exact to="../Suggestions">
                    &#x27A4; Suggestions
                  </NavLink>
                </li>
                <li href={url}>
                  <NavLink exact to="../Terms&amp;condition">
                    &#x27A4; Terms &amp; Conditions
                  </NavLink>
                </li>
                <li href={url}>
                  <NavLink exact to="../Privacypolicy">
                    &#x27A4; Privacy Policy
                  </NavLink>
                </li>
              </ul>
            </div>
            {/*coloumn3*/}
            <div className="col-sm-12 col-md-3">
              <h2 className="list-unstyled-headers">Quick Link</h2>
              <ul className="list-unstyled">
                <li>
                  <NavLink exact to="../signup">
                    {" "}
                    &#x27A4; New User{" "}
                  </NavLink>
                </li>
                <a
                  href="https://forms.gle/VWRz5EeWWEpv8obBA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  &#x27A4; Join Us
                </a>
                <li href={url}>
                  <a
                    href="https://forms.gle/KffwhhVseZo2ATsX7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    &#x27A4; Feedback
                  </a>
                </li>
                <a
                  href="https://forms.gle/j2ZPGUcr2sUurBCKA"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  &#x27A4; Hire Freelancer
                </a>
              </ul>
            </div>
            {/*coloumn4*/}
            <div className="col-sm-12 col-md-3">
              <h2 className="list-unstyled-headers">Join Our Community</h2>
              <ul className="list-unstyled">
                <a
                  href="https://www.instagram.com/whatuwantbe/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon style={{ fontSize: 20 }} />
                </a>
                &nbsp;&nbsp;
                <a
                  href="https://chat.whatsapp.com/FRDTyaZuum9KsWJbNYqR9o"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsAppIcon style={{ fontSize: 20 }} />
                </a>
                &nbsp;&nbsp;
                <a
                  href="https://t.me/whatuwantbe"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TelegramIcon style={{ fontSize: 21 }} />
                </a>
                &nbsp;&nbsp;
                <a
                  href="https://www.linkedin.com/company/wuwb"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon style={{ fontSize: 21 }} />
                </a>
                &nbsp;&nbsp;
                <li>
                  <a href="tel:7536024636">
                    <span className="text">&#9743; +917536024636</span>
                  </a>
                </li>
                <li>
                  <a href="tel:+918005901030">
                    <span className="text">&#9743; +918005901030</span>
                  </a>
                </li>
                <li>
                  <a href="mailto: whatuwantbe@gmail.com">
                    <span className="text">&#x2709; whatuwantbe@gmail.com</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Footer Bottom */}
          <div className="footer-bottom">
            <hr color="#55585cc7" />

            <a
              className="termcondition"
              href="https://www.poornima.org/poornima-college-of-engineering-2/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Supported by : Poornima Innovation and Incubation Cell
              (PIIC),&nbsp; Poornima College of Engineering, Jaipur
            </a>

            <hr color="#55585cc7" />
            <p className="text-xs-center">
              &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp; Best Viewed in Latest Versions of Edge,
              Mozilla, Chrome, Opera & Safari. &nbsp;&nbsp;&nbsp;&nbsp;
              Copyright &copy;{new Date().getFullYear()} @WUWB-All Rights
              Reserved &nbsp; &nbsp; V: 1.2.1
            </p>
          </div>
        </div>
      </div>
    </FooterContainer>
  );
}
export default Footer;

const FooterContainer = styled.footer`
  .footer-middle {
    background: linear-gradient(#d7958e, #d7958e);

    width: 100%;
    font-weight: bold;
    font-family: sans-serif;
    padding-top: 2rem;
    color: var(--mainDark);
  }

  ul li a {
    color: var(--mainDark);
  }
  ul li a :hover {
    color: var(--mainWhite);
  }
  a {
    color: var(--mainDark);
    text-decoration: none;
  }
  a:hover {
    color: var(--mainWhite);
  }

  p {
    color: var(--mainDark);
    margin-left: 6px;
    font-size: 0.9rem;
  }
  .list-unstyled-headers {
    white-space: nowrap;
  }
`;
