import React from "react";
import { Carousel } from "react-bootstrap";
import image1 from "../../assests/banner1.png";
import image2 from "../../assests/banner2.png";
import image3 from "../../assests/banner3.png";
import image4 from "../../assests/banner4.png";
const CarouselContainer = () => {
  return (
    <Carousel prevIcon="" nextIcon="" swipe={true} pause={false}>
      <Carousel.Item interval={4000}>
        <img
          className="d-block w-100"
          src={image1}
          alt="First slide"
          title="First Banner"
        />
      </Carousel.Item>
      <Carousel.Item interval={4000}>
        <img
          className="d-block w-100"
          src={image2}
          alt="Second slide"
          title="Second Banner"
        />
      </Carousel.Item>
      <Carousel.Item interval={4000}>
        <img
          className="d-block w-100"
          src={image3}
          alt="Third slide"
          title="Third Banner"
        />
      </Carousel.Item>

      <Carousel.Item interval={4000}>
        <img
          className="d-block w-100"
          src={image4}
          alt="Fourth slide"
          title="Fourth Banner"
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselContainer;
