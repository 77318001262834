import React, { useState } from "react";
import "./App.css";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {Proxy,TitleURL} from "../../Proxy/Proxy";

const Reset = () => {
  document.title=window.location.href.replace(TitleURL,'WHAT U WANT BE ? ')
  document.title=document.title.replaceAll('/',' | ')
  toast.configure();
  const history = useHistory();
  const [user, setUser] = useState({ email: "" });
  let name, value;
  const handleInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

  const PostData = async (e) => {
    e.preventDefault();
    const { email } = user;

    const res = await fetch(Proxy + "/resetpassword", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      crossDomain: true,
      credentials: "include",
      body: JSON.stringify({
        email,
      }),
    });
    const data = res.json();
    if (!email) {
      toast.warn("Plz fill the field properly", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (res.status === 422 || !data) {
      toast.error("No user Found with this E-mail", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      window.alert("Check Your email");
      history.push("/signin");
    }
  };

  return (
    <div className="App">
      <div class="login-box">
        <h2>Reset Password</h2>
        <form method="Post">
          <div class="user-box">
            <input
              type="text"
              id="email"
              name="email"
              value={user.email}
              onChange={handleInputs}
              required=""
            />
            <label>Enter your Email-Id</label>
          </div>

          <a href="/#" className="submittext" onClick={PostData}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </a>
          <div></div>
        </form>
      </div>
    </div>
  );
};

export default Reset;
