import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Booksearch from "./Booksearch";
const Development = () => {
  const [users, setUsers] = useState([]);
  document.title=window.location.href.replace('https://wuwb.in','WHAT U WANT BE ? ')
  document.title=document.title.replaceAll('/',' | ')
  const getUsers = async () => {
    const response = await fetch(
      "https://vaibhavkhushalani.github.io/myData-Json/development.json"
    );
    setUsers(await response.json());
  };
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="Development">
      <h1 className="h1development">
        <strong>Courses</strong>
      </h1>
      <Booksearch />

      <div className="row">
        {users.map((curlElem) => {
          return (
            <div key={curlElem.id} className="col-sm-6 col-md-4 col-lg-4  ">
              <div className="cards text-centre shadow">
                <div className="overflow">
                  <img
                    src={curlElem.imglink}
                    alt="img"
                    className="cards-img-top"
                  />
                </div>
                <div className="cards-body text-dark">
                  <h5 className="cards-title">
                    <strong>{curlElem.Course}</strong>{" "}
                  </h5>
                  <p className="card-text text-secondary">
                    <strong>{curlElem.Avg}</strong>
                  </p>
                  <NavLink
                    style={{ color: "#fff" }}
                    to={{
                      pathname: `/courses/Development/${curlElem.Course}`,
                    }}
                  >
                    <a className="btn btn-card">Go</a>
                  </NavLink>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Development;
