import React, { useState } from "react";
import "./App.css";
import { NavLink, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Proxy, TitleURL } from "../../Proxy/Proxy";
import { GoogleLogin } from "react-google-login";
import { loginAPI, googleAPI } from "../../utils/api";
import Cookie from "js-cookie";

const Signin = () => {
  document.title = window.location.href.replace(TitleURL, "WHAT U WANT BE ? ");
  document.title = document.title.replaceAll("/", " | ");
  // const { state, dispatch } = useContext(UserContext);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginUser = async (e) => {
    e.preventDefault();
    try {
      const { data } = await loginAPI({ email, password });
      Cookie.set("wuwbToken", data?.token);
      history.push("/courses");
      window.location.reload();
    } catch (error) {
      const { response } = error;
      toast.error(response?.data?.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //Random string
  const random = (length = 8) => {
    // Declare all characters
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    // Pick characers randomly
    let str = "";
    for (let i = 0; i < length; i++) {
      str += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return str;
  };

  //google sign-in

  const SuccessresponseGoogle = async (response) => {
    var email = response.profileObj.email;
    try {
      const { data } = await googleAPI({ email });
      Cookie.set("wuwbToken", data?.token);
      history.push("/courses");
      window.location.reload();
    } catch (error) {
      const { response } = error;
      if (response.status === 400) {
        history.push(`/Confirmationsignup/${random(64)}${email}`);
      }
      toast.error(response?.data?.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const FailureresponseGoogle = () => {
    toast.error("Failed Try Again Later ", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className="App">
      <div className="login-box">
        <h2>Login</h2>
        <div className="Notessignup">
          &#9733;To Use Our Services, You Must Create An Account.
        </div>
        <br />
        <form method="Post">
          <div className="user-box">
            <input
              type="text"
              id="email"
              name=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required=""
            />
            <label>E-mail</label>
          </div>
          <div className="user-box">
            <input
              type="password"
              id="password"
              autoComplete="off"
              name=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required=""
            />
            <label>Password</label>
          </div>

          <a href="#" className="submittext" onClick={loginUser}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Login
          </a>
          <p style={{ color: "#fff", textAlign: "center" }}>Or</p>
          <GoogleLogin
            className="google-Signup"
            clientId="725333776628-l3meog5chh7iabm2747hphbocal9u49s.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={SuccessresponseGoogle}
            onFailure={FailureresponseGoogle}
            cookiePolicy={"single_host_origin"}
          />
          <ToastContainer role="alert" />

          <h6>
            <NavLink exact activeClassName="active-class" to="../reset">
              Forgot Password?
            </NavLink>
          </h6>
          <span></span>
          <br></br>
          <br></br>
          <hr color="#fff" />
          <button type="button" className="btn btn-accountbutton">
            <NavLink exact activeClassName="active-class" to="../signup">
              Create new Account
            </NavLink>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signin;
