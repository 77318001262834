import React from "react";
import "./contactus.css";
import Common from "./Common";



const HelpCenter = () => {
  
  return (<>
  <Common name="Help Center"/>
  </>
    
  );
};

export default HelpCenter;
