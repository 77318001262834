import React, { useState, useEffect } from "react";
import "./Academics.css";
import { NavLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../../Loading/Loading";
import { Proxy, TitleURL } from "../../../Proxy/Proxy";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

export const Academics = () => {
  const [users, setUsers] = useState([]);
  document.title = window.location.href.replace(TitleURL, "WHAT U WANT BE ? ");
  document.title = document.title.replaceAll("/", " | ");
  const getUsers = async () => {
    const response = await fetch(
      "https://vaibhavkhushalani.github.io/myData-Json/academic.json"
    );
    setUsers(await response.json());
  };
  useEffect(() => {
    getUsers();
  }, []);

  const history = useHistory();
  // const [loading, setLoading] = useState(true);
  // const callCoursessaPage = async () => {
  //   try {
  //     const res = await fetch(Proxy + "/Course", {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       credentials: "include",
  //     });
  //     await res.json();
  //     if (!res.status === 200) {
  //       const error = new Error(res.error);
  //       throw error;
  //     }
  //   } catch (err) {
  //     toast.error("You must Login to access this Page", {
  //       position: "top-center",
  //       autoClose: 5000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     history.push("/signin");
  //   }
  //   setLoading(false);

  // };

  const showWhatsapp = () => {
    toast.success(
      <a
        href="https://chat.whatsapp.com/FRDTyaZuum9KsWJbNYqR9o"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "#fff", fontweight: "bold" }}
      >
        {<WhatsAppIcon style={{ fontSize: 20 }} />} Join Our Community
      </a>,
      {
        position: "bottom-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  useEffect(() => {
    showWhatsapp();
  }, []);
  // if (loading)
  //   return (
  //     <div>
  //       <Loading />
  //     </div>
  //   );

  return (
    <>
      <div className="wrapper">
        <h1>
          {" "}
          <strong>
            Academics Courses
            <br />
            B-Tech(CS)
          </strong>
        </h1>
        <div className="row gy-4 text-center">
          {users.map((curlElem, currentItem) => {
            return (
              <div
                key={curlElem.id}
                className="col-md-4 col-10 mx-auto mb-4 "
                onTouchStart="this.classList.toggle('hover')"
              >
                <div className="container">
                  <div
                    className="front"
                    style={{
                      backgroundColor: "#160f0f",
                    }}
                  >
                    <div className="inner">
                      <p>{curlElem.name}</p>

                      <span></span>
                    </div>
                  </div>
                  <div className="back">
                    <div className="inner">
                      <NavLink
                        to={{
                          pathname: "/courses/Academics/Semester",
                          state: { id: currentItem },
                        }}
                      >
                        <p>
                          {curlElem.subjects.map((subject) => {
                            return (
                              <div key={subject.sub_name}>
                                {subject.sub_name}
                              </div>
                            );
                          })}
                        </p>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Academics;
