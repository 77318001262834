import React, { useEffect, useState } from "react";
import "./contactus.css";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Proxy, TitleURL } from "../../Proxy/Proxy";
import { getDetails, postMessage } from "../../utils/api";
import Cookie from "js-cookie";
const Common = (props) => {
  document.title = window.location.href.replace(TitleURL, "WHAT U WANT BE ? ");
  document.title = document.title.replaceAll("/", " | ");
  const history = useHistory();

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const userDetails = async () => {
    try {
      const { data } = await getDetails();
      setUserData({ ...userData, name: data.name, email: data.email });
    } catch (error) {
      const { response } = error;
      console.log(response);
    }
  };
  const checkLogin = () => {
    if (!Cookie.get("wuwbToken")) {
      toast.error("Login to access this page", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("/signin");
    } else {
      userDetails();
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);
  //store data

  const handleInputs = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserData({ ...userData, [name]: value });
  };
  //send data backend
  const contactForm = async (e) => {
    e.preventDefault();
    const { name, email, message } = userData;
    if (!message) {
      toast.warn("Message Field cannot be empty", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      await postMessage({ name, email, message });
      toast.success("Message send", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setUserData({ ...userData, message: "" });
    } catch (error) {
      const { response } = error;
      console.log(response);
      toast.error(response.data.error, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="contactus">
      <div className="contact-box">
        <h2>{props.name}</h2>
        <form method="POST">
          <div className="user-box">
            <div className="form-group">
              <input
                className="form-control"
                rows="3"
                type="textarea"
                id="name"
                name="name"
                value={userData.name}
                required=""
              />
              <label>Full Name</label>
            </div>
            <div className="user-box">
              <div className="form-group">
                <input
                  className="form-control"
                  rows="3"
                  type="textarea"
                  id="email"
                  name="email"
                  value={userData.email}
                  required=""
                />
                <label>E-mail</label>
              </div>
            </div>

            <div className="user-box">
              <div className="form-group">
                <textarea
                  className="form-control"
                  rows="3"
                  type="textarea"
                  id="message"
                  name="message"
                  value={userData.message}
                  onChange={handleInputs}
                  required=""
                />

                <label>Type Your message</label>
              </div>
            </div>
          </div>

          <a className="submittextcontact" href="void(0)" onClick={contactForm}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            Submit
          </a>
          <ToastContainer />
          <div></div>
        </form>
      </div>
    </div>
  );
};

export default Common;
