import React from "react";
import "./loading.css";

const Loading = () => {
  return (
    <div className="loadings">
      <div className="animation">
        <h1 id="showMe">
          <strong>Loading Awesomeness...</strong> Patience is a virtue!
        </h1>
      </div>

      <svg viewBox="0 0 50 50" className="spinner">
        <circle className="ring" cx="25" cy="25" r="22.5" />
        <circle className="line" cx="25" cy="25" r="22.5" />
      </svg>
    </div>
  );
};

export default Loading;
